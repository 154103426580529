import React from 'react';
import pothen3 from '../img/pothen3.jpeg';
import FormLink from './FormLink';
import OutsideResource from './OutsideResource';

const PatientFormsContent = () => {
  return (
    <div className="w-full px-8 flex flex-col py-16 justify-center items-center">
      <div className="w-full lg:w-3/5 flex flex-col lg:flex-row justify-center items-center lg:items-start lg:justify-start mb-12">
        <img alt="staff" className="w-64 max-w-full" src={pothen3} />
        <div className="flex flex-col justify-start items-center lg:items-start px-4">
          <p className="text-lg text-center lg:text-left text-gray-600 mb-4">
            We have several forms available online for you to download and
            complete before coming into the office. This will help ensure you
            are able to see the doctor as quickly as possible.
          </p>
          <p className="text-lg text-center lg:text-left text-gray-600 mb-4">
            The documents on this page are PDF files. In order to open the link,
            you must have Adobe Reader installed on your computer. If you need
            this program, you can download it for free at the Adobe web site.
          </p>
          <p className="text-lg text-center lg:text-left text-gray-600">
            If you already have Adobe Reader installed, then you are prepared to
            view the following documents:
          </p>
        </div>
      </div>
      <div className="w-full lg:w-3/5 flex flex-col justify-center items-start mb-6">
        <p className="text-2xl text-center text-gray-700 font-bold mb-4">
          Download Patient Forms By Clicking on the Form Titles Below
        </p>
        <FormLink path="https://sunstate-medical.s3.amazonaws.com/arnpp.pdf">
          Acknowledgement Of Receipt Of Notice Of Privacy Practices
        </FormLink>
        <FormLink path="https://sunstate-medical.s3.amazonaws.com/npp.pdf">
          Notice Of Privacy Practices
        </FormLink>
        <FormLink path="https://sunstate-medical.s3.amazonaws.com/pi.pdf">
          Patient Information
        </FormLink>
        <FormLink path="https://sunstate-medical.s3.amazonaws.com/psdaq.pdf">
          Patient Self Determination Act Questionnaire
        </FormLink>
        <FormLink path="https://sunstate-medical.s3.amazonaws.com/ppr.pdf">
          Policies And Procedures
        </FormLink>
        <FormLink path="https://sunstate-medical.s3.amazonaws.com/ros.pdf">
          Review Of System
        </FormLink>
        <FormLink path="https://sunstate-medical.s3.amazonaws.com/mrrf.pdf">
          Medical Records Release Form
        </FormLink>
        <FormLink path="https://sunstate-medical.s3.amazonaws.com/padi.pdf">
          Patient Authorization to Disclose Information
        </FormLink>
      </div>
      <div className="w-full lg:w-3/5 flex flex-row justify-center items-center lg:justify-start">
        <a
          href="mailto:contact@lakemaryphysicians.com"
          className="bg-blue-500 rounded text-md text-white px-8 py-3 shadow-lg border border-blue-500 mb-2 self-center uppercase tracking-wider"
        >
          Submit Forms
        </a>
        <p className="text-md text-gray-700 italic ml-4 mb-2">
          (forms will be sent via email)
        </p>
      </div>
    </div>
  );
};

export default PatientFormsContent;
