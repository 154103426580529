import React from "react";
import { Link } from "gatsby";

const FormLink = ({ children, path }) => {
  return (
    <Link to={path} target="_blank">
      <p className="text-lg text-sunstate-blue mb-3">{children}</p>
    </Link>
  );
};

export default FormLink;
