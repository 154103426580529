import React from "react";
import { Link } from "gatsby";

const OutsideResource = ({ children, path }) => {
  return (
    <div className="w-full flex flex-col items-start">
      <p className="text-xl text-gray-600 mb-2">{children}</p>
      <Link className="w-full" to={path}>
        <p className="w-full max-w-full text-lg text-sunstate-blue mb-3 truncate">
          {path}
        </p>
      </Link>
    </div>
  );
};

export default OutsideResource;
