import React from "react";
import Layout from "../../../components/Layout";
import PageHeader from "../../../components/PageHeader";
import PatientFormsContent from "../../../components/PatientFormsContent";
import Conditions from "../../../components/Conditions";
import Information from "../../../components/Information";
import Map from "../../../components/Map";

const PatientForms = () => {
  return (
    <Layout>
      <PageHeader text="Patient Forms" />
      <PatientFormsContent />
      <Conditions />
      <Information />
      <Map />
    </Layout>
  );
};

export default PatientForms;
